<div class="main-container relative h-screen w-screen overflow-hidden">
  <div>
    <video #video loading="eager" fetchpriority="high"
      class="fixed right-0 bottom-0 min-w-[100%] min-h-[100%] object-cover" width="1920" height="1080" [muted]="'muted'"
      preload="metadata" id="vid" [disablePictureInPicture]="true" playsinline loop autoplay>
      <source [src]="videoURL" type="video/mp4" />
    </video>

    <div class="custom-gradient"></div>
  </div>
  <div class="absolute flex justify-center left-0 right-0 top-3">
    <img loading="eager" fetchpriority="high" class="max-w-[106px] md:max-w-[156px]" src="assets/images/logo_new.svg"
      alt="sierra logo" height="40" width="220" />
  </div>
  <div class="absolute top-1/4 w-full text-center text-white">
    <h2 class="text-2xl">Hi, {{ userName }}</h2>
    <p class="mt-3 text-xl">Welcome To Your Dream Home</p>
  </div>
  <div class="absolute bottom-0 flex justify-center w-full">
    <div class="circles relative h-[200px] w-[200px]">
      <div class="circle1"></div>
      <div class="circle2"></div>
      <div class="circle3"></div>
    </div>
    <button class="absolute top-1/2 left-0 right-0 mx-auto -translate-y-1/2 w-[60px] h-[60px]"
      (click)="navigateToDetail()">
      <img loading="eager" fetchpriority="high" class="w-full h-full" src="assets/images/arrow-right.svg"
        alt="arrow right" />
    </button>
  </div>
</div>