import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  secretKey = '';
  userName = '';
  videoURL = '/assets/images/home.mp4';

  constructor(
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.apiService.userInfo.subscribe((res) => {
      if (res) {
        this.secretKey = res.data.findUser.secretKey;
        this.userName = res.data.findUser.name;
      }
    });
  }

  navigateToDetail() {
    this.router.navigate(['/detail/', this.secretKey]);
  }
}
